
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexCfP4CstCBfMeta } from "/builds/bazinga/bs.ch/frontend/pages/[...slug]/index.vue?macro=true";
import { default as index1qoEgsrFlRMeta } from "/builds/bazinga/bs.ch/frontend/layers/allocations/pages/allocations/[caseNumber]/index.vue?macro=true";
import { default as indexszMn1ikrIoMeta } from "/builds/bazinga/bs.ch/frontend/layers/allocations/pages/allocations/index.vue?macro=true";
import { default as indexPmonsHwXU7Meta } from "/builds/bazinga/bs.ch/frontend/layers/multimedia-database/pages/art-credit/index.vue?macro=true";
import { default as indexCcU9gFHR9nMeta } from "/builds/bazinga/bs.ch/frontend/pages/bettingen/index.vue?macro=true";
import { default as indexkFNsCLT4hyMeta } from "/builds/bazinga/bs.ch/frontend/pages/call-for-witnesses/index.vue?macro=true";
import { default as indexSF5wDXHey5Meta } from "/builds/bazinga/bs.ch/frontend/pages/cart/index.vue?macro=true";
import { default as indexosZCdCBBHpMeta } from "/builds/bazinga/bs.ch/frontend/pages/checkout/index.vue?macro=true";
import { default as index9fLBOz0wBWMeta } from "/builds/bazinga/bs.ch/frontend/layers/collection/pages/collection-reminder/signup/index.vue?macro=true";
import { default as indext7Cx8vWOwqMeta } from "/builds/bazinga/bs.ch/frontend/layers/day-care-calculator/pages/day-care-calculator/index.vue?macro=true";
import { default as indexHuobV0Py1nMeta } from "/builds/bazinga/bs.ch/frontend/pages/egov-fa-appellate-court/index.vue?macro=true";
import { default as indexjkOhKtURuuMeta } from "/builds/bazinga/bs.ch/frontend/pages/egov-fa-criminal-court/index.vue?macro=true";
import { default as indexw7e8fMV2ozMeta } from "/builds/bazinga/bs.ch/frontend/pages/egov-fa-funeral-calendar/index.vue?macro=true";
import { default as indexgKHoNGBEoeMeta } from "/builds/bazinga/bs.ch/frontend/layers/emails/pages/email/newsletter/[id]/index.vue?macro=true";
import { default as indexkqES5LmQAMMeta } from "/builds/bazinga/bs.ch/frontend/layers/emails/pages/email/server/newsletter/[id]/index.vue?macro=true";
import { default as indexmIbAyYQSfsMeta } from "/builds/bazinga/bs.ch/frontend/pages/events/[slug]/index.vue?macro=true";
import { default as indexnJfUuomvRXMeta } from "/builds/bazinga/bs.ch/frontend/pages/events/index.vue?macro=true";
import { default as index6NirvH4TyGMeta } from "/builds/bazinga/bs.ch/frontend/pages/garbage-a-z/[...slug]/index.vue?macro=true";
import { default as indexCnRSRlxqvwMeta } from "/builds/bazinga/bs.ch/frontend/pages/garbage-a-z/index.vue?macro=true";
import { default as indexjB0O2ceWqdMeta } from "/builds/bazinga/bs.ch/frontend/layers/government-resolutions/pages/government-resolutions/[id]/index.vue?macro=true";
import { default as indexmbMs13hGr5Meta } from "/builds/bazinga/bs.ch/frontend/layers/government-resolutions/pages/government-resolutions/index.vue?macro=true";
import { default as _91_46_46_46slug_93v6nNYhXtlPMeta } from "/builds/bazinga/bs.ch/frontend/pages/help/[...slug].vue?macro=true";
import { default as indexAN7PySmLKlMeta } from "/builds/bazinga/bs.ch/frontend/pages/help/index.vue?macro=true";
import { default as indexExOw02tBSGMeta } from "/builds/bazinga/bs.ch/frontend/layers/multimedia-database/pages/image-database/index.vue?macro=true";
import { default as index5lwdyT39dtMeta } from "/builds/bazinga/bs.ch/frontend/pages/index.vue?macro=true";
import { default as indexx25nYP25bLMeta } from "/builds/bazinga/bs.ch/frontend/pages/maintenance/index.vue?macro=true";
import { default as _91id_938BFPudBtwZMeta } from "/builds/bazinga/bs.ch/frontend/pages/media/[id].vue?macro=true";
import { default as indexJBBRODD00cMeta } from "/builds/bazinga/bs.ch/frontend/layers/motor-vehicle-calculator/pages/motor-vehicle-calculator/index.vue?macro=true";
import { default as indexLAFQ8mPwS2Meta } from "/builds/bazinga/bs.ch/frontend/pages/news/[slug]/index.vue?macro=true";
import { default as indexuRqSk596nvMeta } from "/builds/bazinga/bs.ch/frontend/pages/news/index.vue?macro=true";
import { default as _91id_93QiJOKwO9bEMeta } from "/builds/bazinga/bs.ch/frontend/pages/node/[id].vue?macro=true";
import { default as _91viewmode_93KQQOtvbFbTMeta } from "/builds/bazinga/bs.ch/frontend/pages/node/preview/[uuid]/[viewmode].vue?macro=true";
import { default as indexYI5hyYKaLzMeta } from "/builds/bazinga/bs.ch/frontend/pages/organizations/index.vue?macro=true";
import { default as indexeMYHSJV8ngMeta } from "/builds/bazinga/bs.ch/frontend/pages/press-releases/[...slug]/index.vue?macro=true";
import { default as index8BraIzqTf2Meta } from "/builds/bazinga/bs.ch/frontend/pages/press-releases/index.vue?macro=true";
import { default as indexnVFjvOt9haMeta } from "/builds/bazinga/bs.ch/frontend/pages/privacy-policy/index.vue?macro=true";
import { default as indexJ5DDrUS2yPMeta } from "/builds/bazinga/bs.ch/frontend/pages/publikationen/[slug]/index.vue?macro=true";
import { default as indexlQaj8bQ1UKMeta } from "/builds/bazinga/bs.ch/frontend/pages/publikationen/index.vue?macro=true";
import { default as indexlWu9iFTqOSMeta } from "/builds/bazinga/bs.ch/frontend/pages/search/index.vue?macro=true";
import { default as indext6Vrn4mE54Meta } from "/builds/bazinga/bs.ch/frontend/layers/social-benefit-calculator/pages/social-benefit-calculator/index.vue?macro=true";
import { default as index6zmtSFQDfhMeta } from "/builds/bazinga/bs.ch/frontend/layers/social-benefit-calculator/pages/social-claim-calculator/index.vue?macro=true";
import { default as indexPKeM7WgXliMeta } from "/builds/bazinga/bs.ch/frontend/layers/social-benefit-calculator/pages/social-help-calculator/index.vue?macro=true";
import { default as indexOKzR2q7xFrMeta } from "/builds/bazinga/bs.ch/frontend/pages/topics/[...slug]/index.vue?macro=true";
import { default as indexXS88qvL2JiMeta } from "/builds/bazinga/bs.ch/frontend/pages/topics/index.vue?macro=true";
import { default as indexg65EvQzgcXMeta } from "/builds/bazinga/bs.ch/frontend/layers/collection/pages/zone-search/index.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexCfP4CstCBfMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "allocations-detail-page",
    path: "/allocations/:caseNumber",
    meta: index1qoEgsrFlRMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/allocations/pages/allocations/[caseNumber]/index.vue")
  },
  {
    name: "allocations",
    path: "/allocations",
    meta: indexszMn1ikrIoMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/allocations/pages/allocations/index.vue")
  },
  {
    name: "art-credit",
    path: "/art-credit",
    meta: indexPmonsHwXU7Meta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/multimedia-database/pages/art-credit/index.vue")
  },
  {
    name: "home-bettingen",
    path: "/bettingen",
    meta: indexCcU9gFHR9nMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/bettingen/index.vue")
  },
  {
    name: "bs-call-for-witnesses",
    path: "/call-for-witnesses",
    meta: indexkFNsCLT4hyMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/call-for-witnesses/index.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexSF5wDXHey5Meta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/cart/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexosZCdCBBHpMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/checkout/index.vue")
  },
  {
    name: "collection-reminder-signup",
    path: "/collection-reminder/signup",
    meta: index9fLBOz0wBWMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/collection/pages/collection-reminder/signup/index.vue")
  },
  {
    name: "day-care-calculator",
    path: "/day-care-calculator",
    meta: indext7Cx8vWOwqMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/day-care-calculator/pages/day-care-calculator/index.vue")
  },
  {
    name: "bs_appellate_court_dates",
    path: "/egov-fa-appellate-court",
    meta: indexHuobV0Py1nMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/egov-fa-appellate-court/index.vue")
  },
  {
    name: "bs_criminal_court_dates",
    path: "/egov-fa-criminal-court",
    meta: indexjkOhKtURuuMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/egov-fa-criminal-court/index.vue")
  },
  {
    name: "bs_funeral_calendar",
    path: "/egov-fa-funeral-calendar",
    meta: indexw7e8fMV2ozMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/egov-fa-funeral-calendar/index.vue")
  },
  {
    name: "email-newsletter",
    path: "/email/newsletter/:id()",
    meta: indexgKHoNGBEoeMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/emails/pages/email/newsletter/[id]/index.vue")
  },
  {
    name: "email-server-newsletter",
    path: "/email/server/newsletter/:id()",
    meta: indexkqES5LmQAMMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/emails/pages/email/server/newsletter/[id]/index.vue")
  },
  {
    name: "events-slug",
    path: "/:prefix(veranstaltungen|events|evenements|eventi)/:slug",
    meta: indexmIbAyYQSfsMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/events/[slug]/index.vue")
  },
  {
    name: "event-calendar-overview",
    path: "/events",
    meta: indexnJfUuomvRXMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/events/index.vue")
  },
  {
    name: "garbage-material-detail",
    path: "/themen/umwelt-und-bauen/abfall-und-sauberkeit/abfall-abc/:slug",
    meta: index6NirvH4TyGMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/garbage-a-z/[...slug]/index.vue")
  },
  {
    name: "garbage-a-z",
    path: "/garbage-a-z",
    meta: indexCnRSRlxqvwMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/garbage-a-z/index.vue")
  },
  {
    name: "government-resolutions-detail-page",
    path: "/regierungsratsbeschluesse/:id",
    meta: indexjB0O2ceWqdMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/government-resolutions/pages/government-resolutions/[id]/index.vue")
  },
  {
    name: "government-resolutions-overview",
    path: "/government-resolutions",
    meta: indexmbMs13hGr5Meta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/government-resolutions/pages/government-resolutions/index.vue")
  },
  {
    name: "help-slug",
    path: "/hilfe/:slug(.*)",
    meta: _91_46_46_46slug_93v6nNYhXtlPMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/help/[...slug].vue")
  },
  {
    name: "help",
    path: "/hilfe",
    meta: indexAN7PySmLKlMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/help/index.vue")
  },
  {
    name: "image-database",
    path: "/image-database",
    meta: indexExOw02tBSGMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/multimedia-database/pages/image-database/index.vue")
  },
  {
    name: "home",
    path: "/",
    meta: index5lwdyT39dtMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexx25nYP25bLMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/maintenance/index.vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/media/[id].vue")
  },
  {
    name: "bs_motor_vehicle_tax_calculator",
    path: "/motor-vehicle-calculator",
    meta: indexJBBRODD00cMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/motor-vehicle-calculator/pages/motor-vehicle-calculator/index.vue")
  },
  {
    name: "news-detail",
    path: "/:prefix(news|derniere-nouvelles)/:slug",
    meta: indexLAFQ8mPwS2Meta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/news/[slug]/index.vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexuRqSk596nvMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/news/index.vue")
  },
  {
    name: "node-id",
    path: "/node/:id()",
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/node/[id].vue")
  },
  {
    name: "node-preview-uuid-viewmode",
    path: "/node/preview/:uuid()/:viewmode()",
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/node/preview/[uuid]/[viewmode].vue")
  },
  {
    name: "organizations-overview",
    path: "/organizations",
    meta: indexYI5hyYKaLzMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/organizations/index.vue")
  },
  {
    name: "press-releases-slug",
    path: "/medienmitteilungen/:slug(.*)",
    meta: indexeMYHSJV8ngMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/press-releases/[...slug]/index.vue")
  },
  {
    name: "press-releases",
    path: "/press-releases",
    meta: index8BraIzqTf2Meta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/press-releases/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: indexnVFjvOt9haMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/privacy-policy/index.vue")
  },
  {
    name: "publication_detail",
    path: "/publikationen/:slug",
    meta: indexJ5DDrUS2yPMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/publikationen/[slug]/index.vue")
  },
  {
    name: "publications",
    path: "/publikationen",
    meta: indexlQaj8bQ1UKMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/publikationen/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexlWu9iFTqOSMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/search/index.vue")
  },
  {
    name: "bs_social_benefit_calculator",
    path: "/social-benefit-calculator",
    meta: indext6Vrn4mE54Meta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/social-benefit-calculator/pages/social-benefit-calculator/index.vue")
  },
  {
    name: "bs_social_claim_calculator",
    path: "/social-claim-calculator",
    meta: index6zmtSFQDfhMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/social-benefit-calculator/pages/social-claim-calculator/index.vue")
  },
  {
    name: "bs_social_help_calculator",
    path: "/social-help-calculator",
    meta: indexPKeM7WgXliMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/social-benefit-calculator/pages/social-help-calculator/index.vue")
  },
  {
    name: "topics-slug",
    path: "/:prefix(themen|topics|themes|temi)/:slug(.*)",
    meta: indexOKzR2q7xFrMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/topics/[...slug]/index.vue")
  },
  {
    name: "topics-overview",
    path: "/topics",
    meta: indexXS88qvL2JiMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/pages/topics/index.vue")
  },
  {
    name: "collection-zone-search",
    path: "/zone-search",
    meta: indexg65EvQzgcXMeta || {},
    component: () => import("/builds/bazinga/bs.ch/frontend/layers/collection/pages/zone-search/index.vue")
  }
]