
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'add': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/add.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"add"} },
  'alva': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/alva.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 100 100","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"alva"} },
  'arrow-east-thin': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/arrow-east-thin.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-east-thin"} },
  'arrow-east': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/arrow-east.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-east"} },
  'arrow-north-east': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/arrow-north-east.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-north-east"} },
  'arrow-south': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/arrow-south.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-south"} },
  'arrow-west': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/arrow-west.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-west"} },
  'attachment': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/attachment.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"attachment"} },
  'baselstab': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/baselstab.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 34","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"baselstab"} },
  'bettingenkelch': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/bettingenkelch.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 393.01 761.51","id":"bettingenkelch"} },
  'breadcrumb': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/breadcrumb.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 12 5","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"breadcrumb"} },
  'calendar': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/calendar.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 14 16","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"calendar"} },
  'card': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/card.svg?raw').then(v => v.default), attributes: {"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"card"} },
  'caret': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/caret.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 10 6","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"caret"} },
  'cart': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/cart.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"cart"} },
  'check': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/check.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 12 9","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"check"} },
  'circle-check': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/circle-check.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"circle-check"} },
  'circle-error': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/circle-error.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"circle-error"} },
  'circle-ko': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/circle-ko.svg?raw').then(v => v.default), attributes: {"width":"40","height":"40","viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"circle-ko"} },
  'circle-ok': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/circle-ok.svg?raw').then(v => v.default), attributes: {"width":"40","height":"40","viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"circle-ok"} },
  'circle-person': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/circle-person.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"circle-person"} },
  'circle-warning': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/circle-warning.svg?raw').then(v => v.default), attributes: {"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"circle-warning"} },
  'close': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/close.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 96 960 960","id":"close"} },
  'dots': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/dots.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","id":"dots"} },
  'download': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/download.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"download"} },
  'easy-to-read': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/easy-to-read.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 696.866 696.866","id":"easy-to-read"} },
  'facebook': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/facebook.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"facebook"} },
  'file': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/file.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"file"} },
  'hands': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/hands.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"hands"} },
  'hinweise_no_rounded': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/hinweise_no_rounded.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 4 22","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg","id":"hinweise_no_rounded"} },
  'hinweise_rounded': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/hinweise_rounded.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"hinweise_rounded"} },
  'instagram': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/instagram.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","id":"instagram"} },
  'linkedin': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/linkedin.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"-1 -1 22 22","id":"linkedin"} },
  'location': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/location.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 32 32","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg","id":"location"} },
  'mail': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/mail.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"mail"} },
  'pen': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/pen.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"pen"} },
  'phone': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/phone.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"phone"} },
  'pin': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/pin.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 10 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"pin"} },
  'play': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/play.svg?raw').then(v => v.default), attributes: {"viewBox":"44.9 44.9 83.2 83.2","fill":"none","xmlns":"http://www.w3.org/2000/svg","style":"filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4));","id":"play"} },
  'print': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/print.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"print"} },
  'reload': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/reload.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"reload"} },
  'search': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/search.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"search"} },
  'send-mail': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/send-mail.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 15 10","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"send-mail"} },
  'share': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/share.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"share"} },
  'threads': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/threads.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","id":"threads"} },
  'trash': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/trash.svg?raw').then(v => v.default), attributes: {"width":"21","height":"21","viewBox":"0 0 21 21","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"trash"} },
  'x_formerly_twitter': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/x_formerly_twitter.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","id":"x_formerly_twitter"} },
  'youtube': { import: () => import('/builds/bazinga/bs.ch/frontend/assets/symbols/youtube.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","id":"youtube"} },
}
  