import { defineGraphqlClientOptions } from 'nuxt-graphql-middleware/dist/runtime/clientOptions'

export default defineGraphqlClientOptions<{
  // The language.
  l: string
  // The build hash.
  h: string
  // The environment.
  e: 'client' | 'server'
}>({
  buildClientContext() {
    const language = useCurrentLanguage()
    const config = useRuntimeConfig()

    return {
      l: language.value,
      h: config.public.buildHash,
      e: import.meta.server ? 'server' : 'client',
    }
  },
})
